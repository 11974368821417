import request from "@/utils/request"
//查询用户中心列表
export function userlist(query) {
    return request({
        url: "/wechatUser/list",
        method: "get",
        params:query
    })
}
//获取用户详情
export function userinfo(id) {
    return request({
        url: "/wechatUser/"+id,
        method: "get",
       
    })
}
//关联用户信息表
export function getAssociatedUser(query) {
    return request({
        url: "/wechatUser/getAssociatedUser",
        method: "get",
        params:query
    })
}
//关联企业信息表
export function getCompany(query) {
    return request({
        url: "/wechatUser/getCompany",
        method: "get",
        params:query
    })
}
//关联项目信息表
export function getInspectDemand(query) {
    return request({
        url: "/wechatUser/getInspectDemand",
        method: "get",
        params:query
    })
}
//获取用户认证信息列表
export function getCertified(query) {
    return request({
        url: "/wechatUser/getCertifiedSecretaryGeneral",
        method: "get",
        params:query
    })
}
//通过或者拒绝认证秘书长
export function putauditor(data) {
    return request({
        url: "/wechatUser/auditor",
        method: "put",
        data:data
    })
}

// 模糊查询商会
export function getApiChamberCommerceResults(query) {
    return request({
        url: "/general/getApiChamberCommerceResults",
        method: "get",
        params:query
    })
}
